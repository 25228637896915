<template>
  <div class="page--list">
    <v-container fluid>
      <v-row>
        <v-col class="py-0">
          <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
        </v-col>
      </v-row>

      <v-row>
        <v-col :class="{ shorten: $vuetify.breakpoint.lgAndUp }" class="elevation-1 pa-0">
        <v-toolbar dense elevation="0">
            <v-spacer></v-spacer>
            <v-menu offset-y transition="slide-y-transition">
              <template #activator="{ on, attrs }">
                <v-btn small plain :ripple="false" v-bind="attrs" v-on="on">
                  <v-icon>mdi-cog-outline</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click.prevent="showCreateDialog">
                  <v-list-item-avatar>
                    <v-btn icon x-small>
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="'Nova Permissão'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>

          <v-data-table
            :headers="grid.headers"
            :items="grid.dataDisplayed"
            :loading="grid.loading"
            locale="pt"
            class="elevation-0 pt-5"
          >
            <template #item.icon="{ item }">
              <v-icon small v-text="item.icon"></v-icon>
            </template>

            <template #item.active="{ item }">
              <v-chip
                :color="getStatusColor(item.active)"
                dark
                small
                v-text="`${item.active ? 'Ativa' : 'Inativa'}`"
              ></v-chip>
            </template>

            <template #item.menu="{ item }">
              <v-chip
                :color="getTypeColor(item.menu)"
                dark
                small
                v-text="`${item.menu ? 'Menu' : 'Regular'}`"
              ></v-chip>
            </template>

            <template #item.actions="{ item }">
            <div>
              <v-icon
                small
                class="mr-2"
                v-if="item.locked"
              >mdi-shield-lock</v-icon>
              <v-icon
                small
                class="mr-2"
                v-if="!item.locked"
                @click="showEditDialog(item)"
                >mdi-pencil</v-icon
              >
              <v-menu
                v-model="item.menuDelete"
                v-if="!item.locked"
                offset-y
                transition="slide-y-transition"
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small>mdi-delete</v-icon>
                </template>
                <v-card max-width="300">
                  <v-card-title class="text-h5"> Tem Certeza? </v-card-title>

                  <v-card-text>
                    Se você continuar, todos os usuários que tiverem esta permissão serão afetados.
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      rounded
                      text
                      small
                      class="py-4"
                      @click="item.menuDelete = false"
                    >
                      Desistir
                    </v-btn>
                    <v-btn
                      color="error darken-1"
                      rounded
                      text
                      small
                      class="py-4"
                      @click="remove(item)"
                    >
                      Remover
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>              
            </template>
          </v-data-table>          

          <v-navigation-drawer
            v-if="$vuetify.breakpoint.lgAndUp || mobileFilter"
            v-model="mobileFilter"
            :permanent="$vuetify.breakpoint.lgAndUp"
            :absolute="$vuetify.breakpoint.lgAndUp"
            :fixed="$vuetify.breakpoint.mdAndDown"
            right
            class="elevation-1"
          >
            <v-card elevation="0" class="pa-4">
              <v-card-title class="grey--text text--darken-2"
                >Filtros</v-card-title
              >
              <v-text-field
                v-model="grid.filters.nameOrDisplayName"
                label="Nome ou Nome de Exibicao"
                dense
                solo
              ></v-text-field>
              <v-text-field
                v-model="grid.filters.url"
                label="Endereço URL"
                dense
                solo
              ></v-text-field>
              <v-select
                v-model="grid.filters.status"
                :items="['Ativa', 'Inativa']"
                clearable
                multiple
                label="Situação"
                dense
                solo
              ></v-select>
              <v-select
                v-model="grid.filters.types"
                :items="['Regular', 'Menu']"
                clearable
                multiple
                label="Tipo"
                dense
                solo
              ></v-select>

              <v-card-actions class="d-flex justify-end">
                <v-btn text @click="clearFilter"> Limpar </v-btn>
                <v-btn color="primary" text @click="filter"> Filtrar </v-btn>
              </v-card-actions>
            </v-card>
          </v-navigation-drawer>
        </v-col>
      </v-row>

      <v-row v-if="$vuetify.breakpoint.mdAndDown" class="mt-13">
        <v-col>
          <v-btn
            color="light-blue darken-3"
            fixed
            dark
            bottom
            right
            fab
            @click="toggleMobileFilterVisibility()"
          >
            <v-badge color="red" dot :value="grid.filtered">
              <v-icon>mdi-filter</v-icon>
            </v-badge>
          </v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog.show" max-width="400" @input="closeDialog">
        <v-card rounded="lg">
          <v-card-title class="text-h5" v-text="dialog.title">
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="py-5 mt-5">
            <v-form ref="form" v-model="dialog.isFormValid" class="px-10" autocomplete="off">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="dialog.data.icon"
                    :append-icon="dialog.data.icon"
                    id="permission_icon"
                    placeholder="Ícone"
                    label="Ícone"
                    dense
                    hint="Informe o ícone da permissão"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="dialog.data.name"
                    id="permission_name"
                    placeholder="Nome"
                    label="Nome"
                    dense
                    hint="Informe o nome da permissão"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="dialog.data.displayName"
                    id="permission_display_name"
                    placeholder="Nome de Exibição"
                    label="Nome de Exibição"
                    dense
                    hint="Qual o nome será exibido?"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="dialog.data.url"
                    id="permission_url"
                    placeholder="Endereço"
                    label="Endereço"
                    dense
                    hint="Informe o endereço do menu"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-switch v-model="dialog.data.active" inset dense label="Ativa" class="mt-0"></v-switch>
                  <v-switch v-model="dialog.data.menu" inset dense label="Menu" class="mt-0"></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="default" text @click="closeDialog">
              Cancelar
            </v-btn>
            <v-btn color="primary" text @click="save" v-text="dialog.saveLabel">
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import notificationMixin from "mixins/notification";
import pageLoadingMixin from "mixins/page-loading";
import configurations from "commons/configurations";

export default {
  name: "Permissions",
  mixins: [notificationMixin, pageLoadingMixin],
  data: () => ({
    breadcrumbs: [
      {
        text: "Início",
        to: "/",
      },
      {
        text: "Exemplos",
        to: "/exemplos/listar",
      },
      {
        text: "Permissões",
        to: "/exemplos/permissoes2",
      },
    ],
    isMobileFilterVisible: false,
    editDialog: false,
    mobileFilter: null,
    grid: {
      loading: false,
      filtered: false,
      filters: {
        nameOrDisplayName: undefined,
        url: undefined,
        status: [],
        types: [],
      },
      headers: [
        { text: "#", value: "id", align: "start", sortable: false },
        { text: "Ícone", value: "icon", sortable: false },
        { text: "Nome", value: "name" },
        {
          text: "Nome de Exibição",
          value: "displayName",
          class: "col-display-name",
          cellClass: "col-display-name",
        },
        { text: "Endereço", value: "url" },
        { text: "Situação", value: "active" },
        { text: "Tipo", value: "menu" },
        { text: "Ações", value: "actions", align: "center", sortable: false },
      ],
      data: [],
      dataDisplayed: []
    },
    dialog: {
      show: false,
      edit: false,
      title: '',
      saveLabel: '',
      isFormValid: undefined,
      data: {
        id: 0,
        icon: "",
        name: "",
        url: "",
        displayName: "",
        active: false,
        menu: false
      }
    }
  }),
  computed: {},
  mounted() {
    this.fetchData();
  },
  methods: {
    showEditDialog(item) {
      this.dialog.show = true;
      this.dialog.edit = true;
      this.dialog.title = 'Editar Permissão';
      this.dialog.saveLabel = 'Salvar';
      this.dialog.data.id = item.id;
      this.dialog.data.icon = item.icon;
      this.dialog.data.name = item.name;
      this.dialog.data.displayName = item.displayName;
      this.dialog.data.url = item.url;
      this.dialog.data.active = item.active;
      this.dialog.data.menu = item.menu;
    },
    showCreateDialog() {
      this.dialog.show = true;
      this.dialog.edit = false;
      this.dialog.title = 'Criar Permissão';
      this.dialog.saveLabel = 'Criar';

      this.dialog.data.id = 0;
      this.dialog.data.icon = 'mdi-check';
      this.dialog.data.name = '';
      this.dialog.data.displayName = '';
      this.dialog.data.url = '';
      this.dialog.data.active = true;
      this.dialog.data.menu = false;
    },
    save() {
      if(this.dialog.edit) {
        this.showPageLoading();
        this.$api()
        .patch(configurations.api.auth.permissionsUri + `/${this.dialog.data.id}`, {
          icone: this.dialog.data.icon,
          nome: this.dialog.data.name,
          nomeDeExibicao: this.dialog.data.displayName,
          enderecoUrl: this.dialog.data.url,
          ativa: this.dialog.data.active,
          menu: this.dialog.data.menu,
        })
        .then(({data}) => {
          if(data.sucesso) {
            this.notifySuccess('Permissão atualizada!');
            this.fetchData().then(() => {
              this.closeDialog();
            }).catch(() => this.closeDialog());
          } else {
            this.notifyError(data.mensagem);
          }
        })
        .catch(({response}) => {
          if (response && response.data) {
            this.notifyError(response.data.mensagem);
          } else {
            this.notifyError("Não conseguimos atualizar a permissão.");
          }
          this.hidePageLoading();
        });
      } else {
        this.showPageLoading();
        this.$api()
        .post(configurations.api.auth.permissionsUri, {
          icone: this.dialog.data.icon,
          nome: this.dialog.data.name,
          nomeDeExibicao: this.dialog.data.displayName,
          enderecoUrl: this.dialog.data.url,
          ativa: this.dialog.data.active,
          menu: this.dialog.data.menu,
        })
        .then(({data}) => {
          if(data.sucesso) {
            this.notifySuccess('Permissão criada!');
            this.fetchData().then(() => {
              this.closeDialog();
            }).catch(() => this.closeDialog());
          } else {
            this.notifyError(data.mensagem);
          }
        })
        .catch(({response}) => {
          if (response && response.data) {
            this.notifyError(response.data.mensagem);
          } else {
            this.notifyError("Não conseguimos criar a permissão.");
          }
          this.hidePageLoading();
        });
      }
    },
    remove(data) {
      console.log('Remove', data);
      this.showPageLoading();
        this.$api()
        .delete(configurations.api.auth.permissionsUri + `/${data.id}`)
        .then(({data}) => {
          if(data.sucesso) {
            this.notifySuccess('Permissão removida!');
            this.fetchData().then(() => {
              this.closeDialog();
            }).catch(() => this.closeDialog());
          } else {
            this.notifyError(data.mensagem);
          }
          data.menuDelete = false; // to close message
        })
        .catch(({response}) => {
          if (response && response.data) {
            this.notifyError(response.data.mensagem);
          } else {
            this.notifyError("Não conseguimos remover a permissão.");
          }
          data.menuDelete = false; // to close message
          this.hidePageLoading();
        });
    },
    closeDialog() {
      this.dialog.edit = false;
      this.dialog.show = false;
      this.dialog.isFormValid = undefined;

      this.dialog.data.id = 0;
      this.dialog.data.icon = '';
      this.dialog.data.name = '';
      this.dialog.data.displayName = '';
      this.dialog.data.active = false;
      this.dialog.data.menu = false;
    },
    getStatusColor(active) {
      return active ? 'green' : 'grey';
    },
    getTypeColor(menu) {
      if (menu) {
        return "info";
      }

      return "blue-grey darken-2";
    },
    toggleMobileFilterVisibility() {
      this.mobileFilter = !this.mobileFilter;
    },
    clearFilter() {
      this.grid.filtered = false;

      this.grid.filters.nameOrDisplayName = undefined;
      this.grid.filters.url = undefined;
      this.grid.filters.status = [];
      this.grid.filters.types = [];

      this.grid.dataDisplayed = this.grid.data;
    },
    filter() {
      if (
        this.grid.filters.nameOrDisplayName ||
        this.grid.filters.url ||
        this.grid.filters.status.length ||
        this.grid.filters.types.length
      ) {
        this.grid.filtered = true;

        this.grid.dataDisplayed = this.grid.data.filter(d => {
          return (this.grid.filters.nameOrDisplayName && new RegExp(this.grid.filters.nameOrDisplayName, 'i').test(d.name))
          || (this.grid.filters.url && new RegExp(this.grid.filters.url, 'i').test(d.url))
          || (this.grid.filters.status.length && this.grid.filters.status.indexOf(d.active ? 'Ativa' : 'Inativa') >= 0)
          || (this.grid.filters.types.length && this.grid.filters.types.indexOf(d.menu ? 'Menu' : 'Regular') >= 0);
        });
      }
      this.mobileFilter = false;
    },
    async fetchData() {
      this.fetchPermissions()
        .then((data) => this.transformData(data))
        .then(() => this.hidePageLoading())
        .catch(() => this.hidePageLoading());
    },
    async fetchPermissions() {
      return this.$api()
        .get(configurations.api.auth.permissionsUri)
        .then(({data}) => data);
    },
    async transformData(data) {
      if(data.sucesso) {
        this.grid.data = data.permissoes.map((permission) => {
          return {
            id: permission.id,
            name: permission.nome,
            displayName: permission.nomeDeExibicao,
            icon: permission.icone,
            url: permission.enderecoUrl,
            active: permission.ativa,
            menu: permission.menu,
            locked: permission.protegida,
            menuDelete: false,
          }
        });

        this.clearFilter();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.redo {
  transform: rotateY(180deg);
}
.shorten {
  max-width: calc(100% - 260px);
}
</style>